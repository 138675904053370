<template>
  <div>
    <el-dialog
        v-dialogDrag
        top="5vh"
        custom-class="rtc-private-video-dialog"
        :title="title"
        :width="width"
        :visible.sync="showRoom"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="onQuit">
      <div style="display: flex;">
        <div class="rtc-private-video">
          <div v-show="isVideo" class="rtc-video-box">
            <div class="rtc-video-friend" v-loading="!isChating" element-loading-text="Wait for answer..."
                 element-loading-background="rgba(0, 0, 0, 0.1)">
              <head-image class="friend-head-image" :id="friend.id" :size="80" :name="friend.nickName"
                          :url="friend.headImage" :isShowUserInfo="false" radius="0">
              </head-image>
              <video ref="remoteVideo" autoplay=""></video>
            </div>
            <div class="rtc-video-mine">
              <video ref="localVideo" autoplay=""></video>
            </div>
          </div>
          <div v-show="!isVideo" class="rtc-voice-box" v-loading="!isChating" element-loading-text="Wait for answer..."
               element-loading-background="rgba(0, 0, 0, 0.1)">
            <head-image class="friend-head-image" :id="friend.id" :size="200" :name="friend.nickName"
                        :url="friend.headImage" :isShowUserInfo="false">
              <div class="rtc-voice-name">{{ friend.nickName }}</div>
            </head-image>
          </div>
          <div class="rtc-control-bar">
            <div title="Cancel" class="icon iconfont icon-phone-reject reject"
                 style="color: red;" @click="onQuit()"></div>
          </div>
        </div>
        <div class="note" v-if="isRecordVideo">
          <span class="header">Doctor Note:</span>
          <textarea v-model="remark" />
        </div>
      </div>
    </el-dialog>
    <rtc-private-acceptor v-if="!isHost && isWaiting" ref="acceptor" :friend="friend" :mode="mode" @accept="onAccept"
                          @reject="onReject"></rtc-private-acceptor>
  </div>
</template>

<script>
import HeadImage from '../common/HeadImage.vue';
import RtcPrivateAcceptor from './RtcPrivateAcceptor.vue';
import ImWebRtc from '@/api/webrtc';
import ImCamera from '@/api/camera';
import RtcPrivateApi from '@/api/rtcPrivateApi'
import http from "@/api/httpRequest";
import {formatDateTime} from "@/api/date";

export default {
  name: 'rtcPrivateVideo',
  components: {
    HeadImage,
    RtcPrivateAcceptor
  },
  props: {
    isRecordVideo: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      camera: new ImCamera(), // 摄像头和麦克风
      webrtc: new ImWebRtc(), // webrtc相关
      API: new RtcPrivateApi(), // API
      audio: new Audio(), // 呼叫音频
      showRoom: false,
      friend: {},
      isHost: false, // 是否发起人
      state: "CLOSE", // CLOSE:关闭  WAITING:等待呼叫或接听 CHATING:聊天中  ERROR:出现异常
      mode: 'video', // 模式 video:视频聊 voice:语音聊天
      localStream: null, // 本地视频流
      remoteStream: null, // 对方视频流
      videoTime: 0,
      videoTimer: null,
      heartbeatTimer: null,
      candidates: [],
      mediaRecorder: null, // 视频录制器
      videoBuffer: [], // 视频数据
      remark: '',
      startTime: null,
    }
  },
  methods: {
    open(rtcInfo) {
      this.showRoom = true;
      this.mode = rtcInfo.mode;
      this.isHost = rtcInfo.isHost;
      this.friend = rtcInfo.friend;
      if (this.isHost) {
        this.onCall();
      }
    },
    initAudio() {
      let url = require(`@/assets/audio/call.wav`);
      this.audio.src = url;
      this.audio.loop = true;
    },
    initRtc() {
      this.webrtc.init(this.configuration)
      this.webrtc.setupPeerConnection((stream) => {
        this.$refs.remoteVideo.srcObject = stream;
        this.remoteStream = stream;

        this.startRecord(stream);
      })
      // 监听候选信息
      this.webrtc.onIcecandidate((candidate) => {
        if (this.state == "CHATING") {
          // 连接已就绪,直接发送
          this.API.sendCandidate(this.friend.id, candidate);
        } else {
          // 连接未就绪,缓存起来，连接后再发送
          this.candidates.push(candidate)
        }
      })
      // 监听连接成功状态
      this.webrtc.onStateChange((state) => {
        if (state == "connected") {
          console.log("webrtc连接成功")
        } else if (state == "disconnected") {
          console.log("webrtc连接断开")
        }
      })
    },
    onCall() {
      if (!this.checkDevEnable()) {
        this.close();
      }
      // 初始化webrtc
      this.initRtc();
      // 启动心跳
      this.startHeartBeat();
      // 打开摄像头
      this.openStream().then(() => {
        this.webrtc.setStream(this.localStream);
        this.webrtc.createOffer().then((offer) => {
          // 发起呼叫
          this.API.call(this.friend.id, this.mode, offer).then(() => {
            // 直接进入聊天状态
            this.state = "WAITING";
            // 播放呼叫铃声
            this.audio.play();
          }).catch(() => {
            this.close();
          })
        })
      }).catch(() => {
        // 呼叫方必须能打开摄像头，否则无法正常建立连接
        this.close();
      })
    },
    onAccept() {
      if (!this.checkDevEnable()) {
        this.API.failed(this.friend.id, "The other device does not support the call")
        this.close();
        return;
      }
      // 进入房间
      this.showRoom = true;
      this.state = "CHATING";
      // 停止呼叫铃声
      this.audio.pause();
      // 初始化webrtc
      this.initRtc();
      // 打开摄像头
      this.openStream().finally(() => {
        this.webrtc.setStream(this.localStream);
        this.webrtc.createAnswer(this.offer).then((answer) => {
          this.API.accept(this.friend.id, answer);
          // 记录时长
          this.startChatTime();
          // 清理定时器
          this.waitTimer && clearTimeout(this.waitTimer);
        })
      })
    },
    onReject() {
      console.log("onReject")
      // 退出通话
      this.API.reject(this.friend.id);
      // 退出
      this.close();
    },
    onHandup() {
      this.API.handup(this.friend.id)
      this.$message.success("You have hung up. The call is over")
      this.close();
    },
    onCancel() {
      this.API.cancel(this.friend.id)
      this.$message.success("Call cancelled, end of call")
      this.close();
    },
    onRTCMessage(msg) {
      // 除了发起通话，如果在关闭状态就无需处理
      if (msg.type != this.$enums.MESSAGE_TYPE.RTC_CALL_VOICE &&
          msg.type != this.$enums.MESSAGE_TYPE.RTC_CALL_VIDEO &&
          this.isClose) {
        return;
      }
      // RTC信令处理
      switch (msg.type) {
        case this.$enums.MESSAGE_TYPE.RTC_CALL_VOICE:
          this.onRTCCall(msg, 'voice')
          break;
        case this.$enums.MESSAGE_TYPE.RTC_CALL_VIDEO:
          this.onRTCCall(msg, 'video')
          break;
        case this.$enums.MESSAGE_TYPE.RTC_ACCEPT:
          this.onRTCAccept(msg)
          break;
        case this.$enums.MESSAGE_TYPE.RTC_REJECT:
          this.onRTCReject(msg)
          break;
        case this.$enums.MESSAGE_TYPE.RTC_CANCEL:
          this.onRTCCancel(msg)
          break;
        case this.$enums.MESSAGE_TYPE.RTC_FAILED:
          this.onRTCFailed(msg)
          break;
        case this.$enums.MESSAGE_TYPE.RTC_HANDUP:
          this.onRTCHandup(msg)
          break;
        case this.$enums.MESSAGE_TYPE.RTC_CANDIDATE:
          this.onRTCCandidate(msg)
          break;
      }
    },
    onRTCCall(msg, mode) {
      this.offer = JSON.parse(msg.content);
      this.isHost = false;
      this.mode = mode;
      this.$http({
        url: `/friend/find/${msg.sendId}`,
        method: 'get'
      }).then((friend) => {
        this.friend = friend;
        this.state = "WAITING";
        this.audio.play();
        this.startHeartBeat();
        // 30s未接听自动挂掉
        this.waitTimer = setTimeout(() => {
          this.API.failed(this.friend.id, "No answer");
          this.$message.error("You did not answer");
          this.close();
        }, 30000)
      })
    },
    onRTCAccept(msg) {
      if (msg.selfSend) {
        // 在其他设备接听
        this.$message.success("It has been answered on another device");
        this.close();
      } else {
        // 对方接受了的通话
        let offer = JSON.parse(msg.content);
        this.webrtc.setRemoteDescription(offer);
        // 状态为聊天中
        this.state = 'CHATING'
        // 停止播放语音
        this.audio.pause();
        // 发送candidate
        this.candidates.forEach((candidate) => {
          this.API.sendCandidate(this.friend.id, candidate);
        })
        // 开始计时
        this.startChatTime()
      }
    },
    onRTCReject(msg) {
      if (msg.selfSend) {
        this.$message.success("Has been rejected in other devices");
        this.close();
      } else {
        this.$message.error("Your request to call was declined");
        this.close();
      }
    },
    onRTCFailed(msg) {
      // 呼叫失败
      this.$message.error(msg.content)
      this.close();
    },
    onRTCCancel() {
      // 对方取消通话
      this.$message.success("The caller cancelled the call");
      this.close();
    },
    onRTCHandup() {
      // 对方挂断
      this.$message.success("The other party has hung up");
      this.close();
    },
    onRTCCandidate(msg) {
      let candidate = JSON.parse(msg.content);
      this.webrtc.addIceCandidate(candidate);
    },

    openStream() {
      return new Promise((resolve, reject) => {
        if (this.isVideo) {
          // 打开摄像头+麦克风
          this.camera.openVideo().then((stream) => {
            this.localStream = stream;
            this.$nextTick(() => {
              this.$refs.localVideo.srcObject = stream;
              this.$refs.localVideo.muted = true;
            })
            resolve(stream);
          }).catch((e) => {
            this.$message.error("Failure to open camera")
            console.log("本摄像头打开失败:" + e.message)
            reject(e);
          })
        } else {
          // 打开麦克风
          this.camera.openAudio().then((stream) => {
            this.localStream = stream;
            this.$refs.localVideo.srcObject = stream;
            resolve(stream);
          }).catch((e) => {
            this.$message.error("Failed to open microphone")
            console.log("打开麦克风失败:" + e.message)
            reject(e);
          })
        }
      })
    },
    startChatTime() {
      this.videoTime = 0;
      this.videoTimer && clearInterval(this.videoTimer);
      this.videoTimer = setInterval(() => {
        this.videoTime++;
      }, 1000)
    },
    checkDevEnable() {
      // 检测摄像头
      if (!this.camera.isEnable()) {
        this.$message.error("Failed to access camera");
        return false;
      }
      // 检测webrtc
      if (!this.webrtc.isEnable()) {
        this.$message.error("Failure to initialize RTC could be due to: Server missing ssl certificate. Your machine does not support WebRTC");
        return false;
      }
      return true;
    },
    startHeartBeat() {
      // 每15s推送一次心跳
      this.heartbeatTimer && clearInterval(this.heartbeatTimer);
      this.heartbeatTimer = setInterval(() => {
        this.API.heartbeat(this.friend.id);
      }, 15000)
    },
    close() {
      this.showRoom = false;
      this.camera.close();
      this.webrtc.close();
      this.audio.pause();
      this.videoTime = 0;
      this.videoTimer && clearInterval(this.videoTimer);
      this.heartbeatTimer && clearInterval(this.heartbeatTimer);
      this.waitTimer && clearTimeout(this.waitTimer);
      this.videoTimer = null;
      this.heartbeatTimer = null;
      this.waitTimer = null;
      this.state = 'CLOSE';
      this.candidates = [];

      this.endRecord();
    },
    onQuit() {
      if (this.isChating) {
        this.onHandup()
      } else if (this.isWaiting) {
        this.onCancel();
      } else {
        this.close();
      }
    },
    startRecord(stream) {
      if(!this.isRecordVideo) {
        return;
      }
      this.videoBuffer = [];    //定义数据
      this.startTime = new Date();

      // const possibleTypes = [
      //   "video/mp4;codecs=h264,aac",
      //   "video/webm;codecs=vp9,opus",
      //   "video/webm;codecs=vp8,opus",
      //   "video/webm;codecs=h264,opus",
      // ];
      const possibleTypes = [
        "video/mp4",
      ];
      const mimeType = possibleTypes.find((mimeType) => {
        return MediaRecorder.isTypeSupported(mimeType);
      });
      try{
        this.mediaRecorder = new MediaRecorder(stream, {mimeType});
        console.log('start media recorder ', mimeType);
      }catch(e){
        console.error("Failed to create MediaRecorder!");
        return;
      }

      this.mediaRecorder.ondataavailable = (e) => {
        if(e && e.data && e.data.size > 0){
          this.videoBuffer.push(e.data);
        }
      };
      this.mediaRecorder.start(500);    //设置时间片存储数据
    },
    endRecord() {
      if (!this.isRecordVideo) {
        return;
      }

      this.mediaRecorder?.stop();
      const blob = new Blob(this.videoBuffer);
      console.log("blob size=" + Math.round(blob.size / 1024) + "KB. ");
      if(blob.size <= 0) return

      const videoFile = new File([blob], `video_record_${new Date().getTime()}.mp4`);
      const formData = new FormData()
      formData.append('patientId', this.friend.id);
      formData.append('remark', this.remark);
      formData.append('videoFile', videoFile)
      formData.append('startTime', formatDateTime(this.startTime));
      formData.append('endTime', formatDateTime(new Date()));
      return http({
        url: `/patient-visit/add`,
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    downloadRecord() {
      const blob = new Blob(this.videoBuffer);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "录屏_" + new Date().getTime() + ".mp4";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 100);
    }
  },
  computed: {
    width() {
      return this.isVideo ? this.isRecordVideo ? '1000px' : '960px' : '360px'
    },
    title() {
      let strTitle = `${this.modeText} call-${this.friend.nickName}`;
      if (this.isChating) {
        strTitle += `(${this.currentTime})`;
      } else if (this.isWaiting) {
        strTitle += `(Calling)`;
      }
      return strTitle;
    },
    currentTime() {
      let min = Math.floor(this.videoTime / 60);
      let sec = this.videoTime % 60;
      let strTime = min < 10 ? "0" : "";
      strTime += min;
      strTime += ":"
      strTime += sec < 10 ? "0" : "";
      strTime += sec;
      return strTime;
    },
    configuration() {
      const iceServers = this.$store.state.configStore.webrtc.iceServers;
      return {
        iceServers: iceServers
      }
    },
    isVideo() {
      return this.mode == "video"
    },
    modeText() {
      return this.isVideo ? "Video" : "Voice";
    },
    isChating() {
      return this.state == "CHATING";
    },
    isWaiting() {
      return this.state == "WAITING";
    },
    isClose() {
      return this.state == "CLOSE";
    }
  },
  mounted() {
    // 初始化音频文件
    this.initAudio();
  },
  created() {
    // 监听页面刷新事件
    window.addEventListener('beforeunload', () => {
      this.onQuit();
    });
  },
  beforeUnmount() {
    this.onQuit();
  }
}
</script>

<style lang="scss">
.rtc-private-video {
  position: relative;
  width: 100%;

  .el-loading-text {
    color: white !important;
    font-size: 16px !important;
  }

  .path {
    stroke: white !important;
  }

  .rtc-video-box {
    position: relative;
    background-color: #eeeeee;

    .rtc-video-friend {
      height: 70vh;

      .friend-head-image {
        position: absolute;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: rotateY(180deg);
      }
    }

    .rtc-video-mine {
      position: absolute;
      z-index: 99999;
      width: 25vh;
      right: 0;
      bottom: -1px;

      video {
        width: 100%;
        object-fit: cover;
        transform: rotateY(180deg);
      }
    }
  }

  .rtc-voice-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    background-color: var(--im-color-primary-light-9);

    .rtc-voice-name {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .rtc-control-bar {
    display: flex;
    justify-content: space-around;
    padding: 10px;

    .icon {
      font-size: 50px;
      cursor: pointer;
    }
  }
}

.note {
  height: 70vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 8px;

  .header {
    font-size: 16px;
  }

  textarea {
    height: 100%;

    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
  }
}
</style>
