<template>
  <el-container style="height: 100vh;">
    <el-aside width="200px" style="background-color: #ac00b7">
      <div
        style="display: flex; align-items: center; justify-content: center; height: 60px; color: #fff; font-size: 26px;">
        Demo</div>
      <el-menu default-active="1" background-color="#ac00b7" text-color="#ffffff" active-text-color="#ffffff">
        <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">PacGenomics</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="display: flex; align-items: center; justify-content: flex-end;">
        <el-dropdown @command="handleCommand">
          <head-image :name="$store.state.userStore.userInfo.nickName" :size="38"
            :url="$store.state.userStore.userInfo.headImageThumb">
          </head-image>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="exit">Logout</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>

      <el-main style="padding: 20px; background-color: #f3f3f9">
        <div style="background-color: #fff; padding: 20px;">
          <el-form inline label-position="left" size="small" v-model="queryParams" ref="queryForm">
            <el-form-item prop="nickName" label="First name">
              <el-input v-model="queryParams.nickName" />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="getUserList">Search</el-button>
              <el-button @click="resetForm('queryForm')">Reset</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="tableData">
            <el-table-column prop="nickName" label="Avatar" width="80">
              <template slot-scope="scope">
                <HeadImage :name="scope.row.nickName" :size="40" :is-show-user-info="false"
                  :online="scope.row.online" />
              </template>
            </el-table-column>
            <el-table-column prop="doctor" label="Doctor" min-width="80" />
            <el-table-column prop="nickName" label="First name" min-width="100" />
            <el-table-column prop="lastName" label="Last name" min-width="100" />
            <el-table-column prop="email" label="Email" min-width="120" />
            <el-table-column prop="sex" label="Gender" min-width="80">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.sex == 0">Male</el-tag>
                <el-tag type="danger" v-else>Female</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="birth" label="Birth" min-width="100" />
            <el-table-column prop="phone" label="Phone" min-width="100" />
            <el-table-column prop="driverLicense" label="Driver's license" min-width="130" />
            <el-table-column prop="passport" label="Passport" min-width="90" />
            <el-table-column prop="insurance" label="Insurance" min-width="90" />
            <el-table-column prop="memberId" label="Member id" min-width="110" />
            <el-table-column prop="payerId" label="Payer id" min-width="100" />
            <el-table-column prop="address" label="Address" min-width="140" />
            <el-table-column prop="city" label="City" min-width="130" />
            <el-table-column prop="state" label="State" min-width="60" />
            <el-table-column prop="postal" label="Postal" min-width="70" />
            <el-table-column prop="country" label="Country" min-width="80" />
            <el-table-column label="Action" fixed="right" min-width="230">
              <template slot-scope="scope">
                <el-button type="success" size="mini" icon="el-icon-notebook-1" plain round
                  @click="onShowPatientVisitList(scope.row)">Record</el-button>
                <el-button type="info" size="mini" icon="el-icon-download" plain round
                  @click="onDownload(scope.row)">Download</el-button>
                <el-button type="primary" size="mini" icon="el-icon-video-camera" plain round @click="onCall(scope.row)"
                  v-if="scope.row.online" style="margin-top: 4px">Video call</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="display: flex; align-items: center; justify-content: flex-end; margin-top: 10px;">
            <el-pagination @size-change="() => { }" @current-change="() => { }" :current-page="1"
              :page-sizes="[10, 20, 50, 100]" :page-size="10" layout="total, sizes, prev, pager, next, jumper"
              :total="tableData.length">
            </el-pagination>
          </div>
        </div>
      </el-main>
    </el-container>

    <rtc-private-video ref="rtcPrivateVideo" is-record-video />
    <el-dialog title="Record export" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div style="font-size: 16px;margin-bottom: 20px;">Select a date. If no date is selected, all records will be downloaded.</div>
      <el-date-picker v-model="date" type="daterange" range-separator="To" placeholder="Select date"
        start-placeholder="StartDate" end-placeholder="EedDate">
      </el-date-picker>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click=" getFile">OK</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Record" :visible.sync="showPatientVisit" width="75%">
      <PatientVisit :patientId="row.id" show-remark />
    </el-dialog>
  </el-container>
</template>

<script>
import HeadImage from '../components/common/HeadImage.vue';
import RtcPrivateVideo from '../components/rtc/RtcPrivateVideo.vue';
import RtcPrivateAcceptor from '../components/rtc/RtcPrivateAcceptor.vue';
import PatientVisit from "@/components/PatientVisit.vue";
import { Loading, Message } from "element-ui";
import { saveAs } from 'file-saver'
import {formatDateTime} from "@/api/date";

export default {
  components: {
    PatientVisit,
    HeadImage,
    RtcPrivateVideo,
    RtcPrivateAcceptor,
  },
  data() {
    return {
      lastPlayAudioTime: new Date().getTime() - 1000,
      dialogVisible: false,
      date: [],
      tableData: [],
      queryParams: {
        nickName: "",
        type: 1,
      },
      showPatientVisit: false,
      row: {},
    }
  },
  methods: {
    handleClose(done) {
      done();
    },
    init() {
      this.$store.dispatch("load").then(() => {
        // ws初始化
        this.$wsApi.connect(process.env.VUE_APP_WS_URL, sessionStorage.getItem("accessToken"));
        this.$wsApi.onConnect(() => {
          // 加载离线消息
          this.pullPrivateOfflineMessage(this.$store.state.chatStore.privateMsgMaxId);
        });
        this.$wsApi.onMessage((cmd, msgInfo) => {
          if (cmd == 2) {
            // 关闭ws
            this.$wsApi.close(3000)
            // 异地登录，强制下线
            this.$alert("You have logged in elsewhere and will be forced to log out", "Force offline notifications", {
              confirmButtonText: 'Ok',
              callback: action => {
                location.href = "/";
              }
            });

          } else if (cmd == 3) {
            // 插入私聊消息
            this.handlePrivateMessage(msgInfo);
          }
        });
        this.$wsApi.onClose((e) => {
          console.log(e);
          if (e.code != 3000) {
            // 断线重连
            this.$message.error("Connection lost, trying to reconnect...");
            this.$wsApi.reconnect(process.env.VUE_APP_WS_URL, sessionStorage.getItem(
              "accessToken"));
          }
        });
      }).catch((e) => {
        console.log("初始化失败", e);
      })
    },
    pullPrivateOfflineMessage(minId) {
      this.$store.commit("loadingPrivateMsg", true)
      this.$http({
        url: "/message/private/pullOfflineMessage?minId=" + minId,
        method: 'GET'
      }).catch(() => {
        this.$store.commit("loadingPrivateMsg", false)
      })
    },
    handlePrivateMessage(msg) {
      // 消息加载标志
      if (msg.type == this.$enums.MESSAGE_TYPE.LOADING) {
        this.$store.commit("loadingPrivateMsg", JSON.parse(msg.content))
        return;
      }
      // 消息已读处理，清空已读数量
      if (msg.type == this.$enums.MESSAGE_TYPE.READED) {
        this.$store.commit("resetUnreadCount", {
          type: 'PRIVATE',
          targetId: msg.recvId
        })
        return;
      }
      // 消息回执处理,改消息状态为已读
      if (msg.type == this.$enums.MESSAGE_TYPE.RECEIPT) {
        this.$store.commit("readedMessage", {
          friendId: msg.sendId
        })
        return;
      }
      // 标记这条消息是不是自己发的
      msg.selfSend = msg.sendId == this.$store.state.userStore.userInfo.id;
      // 单人webrtc 信令
      if (this.$msgType.isRtcPrivate(msg.type)) {
        this.$refs.rtcPrivateVideo.onRTCMessage(msg)
        return;
      }
      // 好友id
      let friendId = msg.selfSend ? msg.recvId : msg.sendId;
      this.loadFriendInfo(friendId).then((friend) => {
        this.insertPrivateMessage(friend, msg);
      })
    },
    insertPrivateMessage(friend, msg) {

      let chatInfo = {
        type: 'PRIVATE',
        targetId: friend.id,
        showName: friend.nickName,
        headImage: friend.headImage
      };
      // 打开会话
      this.$store.commit("openChat", chatInfo);
      // 插入消息
      this.$store.commit("insertMessage", msg);
      // 播放提示音
      if (!msg.selfSend && this.$msgType.isNormal(msg.type) &&
        msg.status != this.$enums.MESSAGE_STATUS.READED) {
        this.playAudioTip();
      }
    },
    onExit() {
      this.$wsApi.close(3000);
      sessionStorage.removeItem("accessToken");
      location.href = "/";
    },
    playAudioTip() {
      // 离线消息不播放铃声
      if (this.$store.getters.isLoading()) {
        return;
      }
      // 防止过于密集播放
      if (new Date().getTime() - this.lastPlayAudioTime > 1000) {
        this.lastPlayAudioTime = new Date().getTime();
        let audio = new Audio();
        let url = require(`@/assets/audio/tip.wav`);
        audio.src = url;
        audio.play();
      }

    },
    loadFriendInfo(id) {
      return new Promise((resolve, reject) => {
        let friend = this.$store.state.friendStore.friends.find((f) => f.id == id);
        if (friend) {
          resolve(friend);
        } else {
          this.$http({
            url: `/friend/find/${id}`,
            method: 'get'
          }).then((friend) => {
            this.$store.commit("addFriend", friend);
            resolve(friend)
          })
        }
      });
    },
    onCall(friend) {
      const rtcInfo = {
        mode: 'video',
        isHost: true,
        friend: friend,
      }
      // 进入单人视频通话
      this.$refs.rtcPrivateVideo.open(rtcInfo);
    },
    onShowPatientVisitList(row) {
      this.showPatientVisit = true;
      this.row = row;
    },
    onDownload(row) {
      this.dialogVisible = true
      this.row = row
    },
    getFile() {
      const downloadLoadingInstance = Loading.service({ text: "downloading...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
      this.$http({
        url: `/patient-visit/export?patientId=${this.row.id}&startTime=${formatDateTime(this.date[0])}&endTime=${formatDateTime(this.date[1])}`,
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        responseType: 'blob',
      }).then(async (data) => {
        const isBlob = data?.type !== 'application/json';
        if (isBlob) {
          const blob = new Blob([data])
          saveAs(blob, `${this.row.nickName}.xlsx`)
        } else {
          const resText = await data.text();
          const rspObj = JSON.parse(resText);
          const errMsg = rspObj.msg || 'error'
          Message.error(errMsg);
        }
        downloadLoadingInstance.close();
      }).catch((r) => {
        console.error(r)
        Message.error('There was an error downloading the file, please contact the administrator!')
        downloadLoadingInstance.close();
      })
    },
    resetForm() {
      this.queryParams.nickName = '';
      this.$refs['queryForm'].resetFields();
      this.getUserList();
    },
    handleCommand(command) {
      switch (command) {
        case "exit":
          this.onExit();
          break;
      }
    },
    getUserList() {
      this.$http({
        url: "/user/list",
        method: 'get',
        params: this.queryParams,
      }).then((list) => {
        this.tableData = list;
      });
    },
  },
  computed: {
  },
  mounted() {
    this.init();
    this.getUserList();
  },
  unmounted() {
    this.$wsApi.close();
  }
}
</script>

<style>
.el-table--scrollable-x .el-table__body-wrapper {
  z-index: 3;
}

.el-table__body-wrapper::-webkit-scrollbar {
  height: 8px;
}
</style>
