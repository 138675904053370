import Vue from 'vue';
import Vuex from 'vuex';
import userStore from './userStore.js';
import configStore from './configStore.js';
import uiStore from './uiStore.js';

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {userStore,configStore,uiStore},
	state: {},
	mutations: {
	},
	actions: {
		load(context) {
			return this.dispatch("loadUser").then(() => {
				const promises = [];
				promises.push(this.dispatch("loadConfig"));
				return Promise.all(promises);
			})
		},
		unload(context){
			context.commit("clear");
		}
	},
	strict: process.env.NODE_ENV !== 'production'
})
