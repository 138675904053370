import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/Login'
import Home from '../view/Home'
import PatientHome from "@/view/PatientHome";

// 安装路由
Vue.use(VueRouter);

// 配置导出路由
export default new VueRouter({
  routes: [{
    path: "/",
    redirect: "/login"
  },
    {
      name: "Login",
      path: '/login',
      component: Login
    },
    {
      name: "Record",
      path: '/home2',
      component: PatientHome
    },
    {
      name: "Home",
      path: '/home',
      component: Home,
    }
  ]

});
