<template>
  <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-4 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <a href="/" class="d-block">
                          <img src="@/assets/pacgen/images/logo.png" alt="" height="auto">
                        </a>
                      </div>
                      <div class="mt-auto">
                        <div class="mb-3">
                          <i class="ri-double-quotes-l display-4 text-success"></i>
                        </div>

                        <div id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                          <div class="carousel-indicators">
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>

                          </div>
                          <div class="carousel-inner text-center text-white-50 pb-5">
                            <div class="carousel-item active">
                              <p class="fs-15 fst-italic">"PacGenomics Molecular Pathology Laboratory "</p>
                            </div>

                          </div>
                        </div>
                        <!-- end carousel -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <h5 class="text-primary">Welcome!</h5>
                      <p class="text-muted">Sign in to PacGenomics Dashboard.</p>
                    </div>

                    <div class="mt-4">
                        <div class="mb-3">
                          <label for="username" class="form-label">Username</label>
                          <input type="text" class="form-control" id="username" name="username" placeholder="Enter username" v-model="loginForm.userName">
                        </div>

                        <div class="mb-3">
                          <div class="float-end">
                            <a href="#" class="text-muted">Forgot password?</a>
                          </div>
                          <label class="form-label" for="password-input">Password</label>
                          <div class="position-relative auth-pass-inputgroup mb-3">
                            <input type="password" class="form-control pe-5 password-input" name="password" placeholder="Enter password" id="password-input" v-model="loginForm.password">
                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon"><i class="ri-eye-fill align-middle"></i></button>
                          </div>
                        </div>

                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="auth-remember-check">
                          <label class="form-check-label" for="auth-remember-check">Remember me</label>
                        </div>

                        <div class="mt-4">
                          <button class="btn btn-success w-100" name="login_submit" @click="submitForm">Sign In</button>
                        </div>
                    </div>

                    <div class="mt-5 text-center">
                      <p class="mb-0">Don't have an account ? <router-link to="#" class="fw-semibold text-primary text-decoration-underline"> Signup</router-link> </p>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->

        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">&copy;
                2024 PacGenomics. Design & Develop <i class="mdi mdi-heart text-danger"></i> by Yajie Xu from PacGenomics
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>
</template>

<script>
	export default {
		name: "login",
		components: {},
		data() {
			return {
				loginForm: {
					terminal: this.$enums.TERMINAL_TYPE.WEB,
					userName: '',
					password: ''
				},
			};
		},
		methods: {
			submitForm() {
        if(!(this.loginForm.userName?.length > 0)) {
          this.$message.error('please enter username');
          return;
        }
        if(!(this.loginForm.password?.length > 0)) {
          this.$message.error('please enter password');
          return;
        }
        this.$http({
            url: "/login",
            method: 'post',
            data: this.loginForm
          })
          .then((data) => {
            // 保存密码到cookie(不安全)
            this.setCookie('username', this.loginForm.userName);
            this.setCookie('password', this.loginForm.password);
            // 保存token
            sessionStorage.setItem("accessToken", data.accessToken);
            sessionStorage.setItem("refreshToken", data.refreshToken);
            this.$message.success("login success");
            this.$router.push(data.userType == 2? "/home": '/home2');
          })
			},
			getCookie(name) {
				let reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
				let arr = document.cookie.match(reg)
				if (arr) {
					return unescape(arr[2]);
				}
				return '';
			},
			setCookie(name, value) {
				document.cookie = name + "=" + escape(value);
			}

		},
		mounted() {
			this.loginForm.userName = this.getCookie("username");
			// cookie存密码并不安全，暂时是为了方便
			this.loginForm.password = this.getCookie("password");
		}
	}
</script>

<style src="../assets/pacgen/css/bootstrap.css"></style>
<style src="../assets/pacgen/css/icons.min.css"></style>
<style src="../assets/pacgen/css/app.min.css"></style>
<style src="../assets/pacgen/css/custom.min.css"></style>
<style src="../assets/pacgen/style.css"></style>
<style scoped>
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
</style>