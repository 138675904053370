<template>
  <el-container style="height: 100vh;">
    <el-aside width="200px" style="background-color: #ac00b7">
      <div style="display: flex; align-items: center; justify-content: center; height: 60px; color: #fff; font-size: 26px;">Demo</div>
      <el-menu default-active="1" background-color="#ac00b7" text-color="#ffffff" active-text-color="#ffffff">
        <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">Record</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="display: flex; align-items: center; justify-content: flex-end;">
        <el-dropdown @command="handleCommand">
          <head-image :name="$store.state.userStore.userInfo.nickName"
                      :size="38"
                      :url="$store.state.userStore.userInfo.headImageThumb">
          </head-image>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="exit">Logout</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-header>

      <el-main style="padding: 20px; background-color: #f3f3f9">
        <div style="background-color: #fff; padding: 20px;">
          <PatientVisit :patientId="$store.state.userStore.userInfo.id" :height="'400px'" />
        </div>
      </el-main>
    </el-container>
    <rtc-private-video ref="rtcPrivateVideo" />
  </el-container>
</template>

<script>
import HeadImage from '../components/common/HeadImage.vue';
import RtcPrivateVideo from '../components/rtc/RtcPrivateVideo.vue';
import RtcPrivateAcceptor from '../components/rtc/RtcPrivateAcceptor.vue';
import PatientVisit from "@/components/PatientVisit.vue";

export default {
  components: {
    PatientVisit,
    HeadImage,
    RtcPrivateVideo,
    RtcPrivateAcceptor,
  },
  data() {
    return {
      lastPlayAudioTime: new Date().getTime() - 1000,
      tableData: [],
      queryParams: {
        nickName: "",
      },
      showPatientVisit: false,
      row: {},
    }
  },
  methods: {
    init() {
      this.$store.dispatch("load").then(() => {
        // ws初始化
        this.$wsApi.connect(process.env.VUE_APP_WS_URL, sessionStorage.getItem("accessToken"));
        this.$wsApi.onConnect(() => {
          // 加载离线消息
          this.pullPrivateOfflineMessage(this.$store.state.chatStore.privateMsgMaxId);
        });
        this.$wsApi.onMessage((cmd, msgInfo) => {
          if (cmd == 2) {
            // 关闭ws
            this.$wsApi.close(3000)
            // 异地登录，强制下线
            this.$alert("You have logged in elsewhere and will be forced to log out", "Force offline notifications", {
              confirmButtonText: 'Ok',
              callback: action => {
                location.href = "/";
              }
            });

          } else if (cmd == 3) {
            // 插入私聊消息
            this.handlePrivateMessage(msgInfo);
          }
        });
        this.$wsApi.onClose((e) => {
          console.log(e);
          if (e.code != 3000) {
            // 断线重连
            this.$message.error("Connection lost, trying to reconnect...");
            this.$wsApi.reconnect(process.env.VUE_APP_WS_URL, sessionStorage.getItem(
                "accessToken"));
          }
        });
      }).catch((e) => {
        console.log("初始化失败", e);
      })
    },
    pullPrivateOfflineMessage(minId) {
      this.$store.commit("loadingPrivateMsg", true)
      this.$http({
        url: "/message/private/pullOfflineMessage?minId=" + minId,
        method: 'GET'
      }).catch(() => {
        this.$store.commit("loadingPrivateMsg", false)
      })
    },
    handlePrivateMessage(msg) {
      // 消息加载标志
      if (msg.type == this.$enums.MESSAGE_TYPE.LOADING) {
        this.$store.commit("loadingPrivateMsg", JSON.parse(msg.content))
        return;
      }
      // 消息已读处理，清空已读数量
      if (msg.type == this.$enums.MESSAGE_TYPE.READED) {
        this.$store.commit("resetUnreadCount", {
          type: 'PRIVATE',
          targetId: msg.recvId
        })
        return;
      }
      // 消息回执处理,改消息状态为已读
      if (msg.type == this.$enums.MESSAGE_TYPE.RECEIPT) {
        this.$store.commit("readedMessage", {
          friendId: msg.sendId
        })
        return;
      }
      // 标记这条消息是不是自己发的
      msg.selfSend = msg.sendId == this.$store.state.userStore.userInfo.id;
      // 单人webrtc 信令
      if (this.$msgType.isRtcPrivate(msg.type)) {
        this.$refs.rtcPrivateVideo.onRTCMessage(msg)
        return;
      }
      // 好友id
      let friendId = msg.selfSend ? msg.recvId : msg.sendId;
      this.loadFriendInfo(friendId).then((friend) => {
        this.insertPrivateMessage(friend, msg);
      })
    },
    insertPrivateMessage(friend, msg) {

      let chatInfo = {
        type: 'PRIVATE',
        targetId: friend.id,
        showName: friend.nickName,
        headImage: friend.headImage
      };
      // 打开会话
      this.$store.commit("openChat", chatInfo);
      // 插入消息
      this.$store.commit("insertMessage", msg);
      // 播放提示音
      if (!msg.selfSend && this.$msgType.isNormal(msg.type) &&
          msg.status != this.$enums.MESSAGE_STATUS.READED) {
        this.playAudioTip();
      }
    },
    onExit() {
      this.$wsApi.close(3000);
      sessionStorage.removeItem("accessToken");
      location.href = "/";
    },
    playAudioTip() {
      // 离线消息不播放铃声
      if (this.$store.getters.isLoading()) {
        return;
      }
      // 防止过于密集播放
      if (new Date().getTime() - this.lastPlayAudioTime > 1000) {
        this.lastPlayAudioTime = new Date().getTime();
        let audio = new Audio();
        let url = require(`@/assets/audio/tip.wav`);
        audio.src = url;
        audio.play();
      }

    },
    loadFriendInfo(id) {
      return new Promise((resolve, reject) => {
        let friend = this.$store.state.friendStore.friends.find((f) => f.id == id);
        if (friend) {
          resolve(friend);
        } else {
          this.$http({
            url: `/friend/find/${id}`,
            method: 'get'
          }).then((friend) => {
            this.$store.commit("addFriend", friend);
            resolve(friend)
          })
        }
      });
    },
    handleCommand(command) {
      switch (command) {
        case "exit":
          this.onExit();
          break;
      }
    }
  },
  computed: {
  },
  mounted() {
    this.init();

    this.$http({
      url: "/user/list",
      method: 'get',
      params: {type: 1}
    }).then((list) => {
      this.tableData = list;
    });
  },
  unmounted() {
    this.$wsApi.close();
  }
}
</script>

<style scoped lang="scss">


</style>
