<template>
  <el-row :gutter="20">
    <el-col :span="8" v-for="(item, index) in list" :key="index">
      <el-card :body-style="{ padding: '0px' }" shadow="hover" style="margin-top: 20px">
        <div class="f-c m10">
          <video :src="item.videoUrl" controls :height="height" width="50%" style="object-fit: cover" />
          <div class="note  " style="width: 100%;">
            <div class="f-c ">
              <div class="c5e">Doctor Note:&nbsp; </div>
              <div class="timec">{{ item.remark }}</div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="bottom clearfix" style="color: #606266">
            <time class="time f-c ">
              <div class="date c5e">Record Time：</div> <span class="timec">{{ getLogAngeleDate(item.createdTime)
                }}</span>
            </time>
            <div class="time f-c ">
              <div class="date c5e">Start Time：</div><span class="timec"> {{ getLogAngeleDate(item.startTime) }}</span>
            </div>
            <div class="time f-c ">
              <div class="date c5e">End Time：</div> <span class="timec">{{ getLogAngeleDate(item.endTime) }}</span>
            </div>
            <div class="time f-c ">
              <div class="date c5e">Duration：</div> <span class="timec">{{ item.durationTime }}</span>
            </div>
            <el-tooltip class="single-line-ellipsis" effect="dark" placement="top" v-if="showRemark"
              :disabled="!item.remark" :content="item.remark">

            </el-tooltip>
          </div>
        </div>
        <i class="el-icon-delete del hand" @click="handleDelete(item.id)"></i>

      </el-card>
    </el-col>
    <el-empty v-if="list.length === 0" />
  </el-row>
</template>

<script>
import HeadImage from "@/components/common/HeadImage.vue";

export default {
  components: { HeadImage },
  props: {
    patientId: Number,
    height: {
      type: String,
      default: "200px",
    },
    showRemark: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
    }
  },
  computed: {
  },
  watch: {
    patientId: {
      immediate: true,
      handler() {
        this.open();
      }
    }
  },
  methods: {
    getLogAngeleDate(time) {
      const originalTime = time;
      const date = new Date(originalTime);
      const losangeles = date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
        hour12: false
      });
      return losangeles

    },
    open() {
      this.getList();
    },
    getList() {
      this.list = [];
      this.$http({
        url: "/patient-visit/list",
        method: 'get',
        params: { patientId: this.patientId }
      }).then((list) => {
        this.list = list;
        this.dialogVisible = true;
      });
    },
    handleDelete(id) {
      this.$confirm('Are you sure you want to delete it?', 'Tips', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: "/patient-visit",
          method: 'delete',
          params: { id }
        }).then(() => {
          this.$message.success('Delete success')
          this.getList();
        });
      })

    }
  },
  mounted() {
  },
  unmounted() {

  }
};
</script>

<style>
.hand {
  cursor: pointer;
}

.single-line-ellipsis {
  white-space: nowrap;
  /* 禁止文本换行 */
  overflow: hidden;
  /* 隐藏超出范围的内容 */
  text-overflow: ellipsis;
  /* 使用省略号 */
}

.f-c {
  display: flex;
}

.m10 {
  margin: 10px;

}

.note {
  margin-left: 10px;
  height: 200px;
  font-size: 15px;
}

.date {
  font-size: 15px;
  width: 120px;
}

.c5e {
  color: #5e5e5e;

}

.box {
  margin: 10px;
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.el-card__body {
  position: relative;
}

.del {
  position: absolute;
  top: -7px;
  right: 3px;
  z-index: 11111;
  font-size: 20px;

}

.timec {
  color: #333;
}
</style>